import axios from 'axios';
import wx from 'weixin-js-sdk';

// requestUrl: 'https://work.goh8.com',
// var requestUrl ='http://192.168.1.118:8081';
// var requestUrl = 'http://192.168.0.11:8082';
// var requestUrl = 'http://jianzhi.goh8.com:8080';
var requestUrl = 'https://www.huitao520.top';
// var requestUrl = 'https://mall.huitao588.top';
// var requestUrl = 'https://merchant.level-job.com';
// var requestUrl = 'https://mall.level-job.com';

export default class Api {

    static getInstance() {

        if (!Api.instance) {

            Api.instance = new Api();
        }
        return Api.instance;
    }
    doGetDeserveItem(params) {
        return this.callback('/api/fanli/doGetDeserveItem.action', params);
    }

    doColumnList(params) {
        return this.callback('/api/fanli/doColumnList.action', params);
    }

    doSuperSearch(params) {
        return this.callback('/api/fanli/doSuperSearch.action', params);
    }

    doHotKey(params) {
        return this.callback('/api/fanli/doHotKey.action', params);
    }

    doItemInfo(params) {
        return this.callback('/api/fanli/doItemInfo.action', params);
    }

    doItemInfoFromId(params) {
        return this.callback('/api/fanli/doItemInfoFromId.action', params);
    }

    doSimilarList(params) {
        return this.callback('/api/fanli/doSimilarList.action', params);
    }

    doPrivilege(params) {
        return this.callback('/api/fanli/doPrivilege.action', params);
    }
    /* 发送手机号验证码 */
    sendVeryCode(params) {
        return this.callback('/api/login/sendVeryCode.action', params);
    }
    /* 手机号登录 */
    doMobileLogin(params) {
        return this.callback('/api/login/mobileLogin.action', params);
    }
    /* 微信授权登录 */
    doCodeLogin(params) {
        return this.callback('/api/login/codeLogin.action', params);
    }
    /* 提现 */
    doCustomerTakeCashList(params) {
        return this.callback('/api/customerTakeCash/doCustomerTakeCashList.action', params);
    }
    doPublishCustomerTakeCash(params) {
        return this.callback('/api/customerTakeCash/doPublishCustomerTakeCash.action', params);
    }
    /* 账户明细 */
    doAccountDetailList(params) {
        return this.callback('/api/customerAccountDetail/doAccountDetailList.action', params);
    }
    /* 订单列表 */
    doTbkOrderList(params) {
        return this.callback('/api/customerTbkOrder/doTbkOrderList.action', params);
    }
    /* 用户账户详情 */
    doBalance(params) {
        return this.callback('/api/customer/customer/doBalance.action', params);
    }
    /* 积分明细 */
    doCreditLogList(params) {
        return this.callback('/api/customerCreditLog/doCreditLogList.action', params);
    }
    /* 邀请奖励统计-邀请好友页面 */
    doInviteCount(params) {
        return this.callback('/api/customerRecommender/doInviteCount.action', params);
    }
    /* 邀请列表-邀请记录页面 */
    doInviteList(params) {
        return this.callback('/api/customerRecommender/doInviteList.action', params);
    }
    /* 邀请列表-邀请记录页面 新返回的数据结构变动，不影响旧版本 */
    doInviteListNew(params) {
        return this.callback('/api/customerRecommender/doInviteListNew.action', params);
    }
    /* 邀请奖励-邀请收益页面 */
    doInviteRewardList(params) {
        return this.callback('/api/customerRecommender/doInviteRewardList.action', params);
    }
    /* 签到-签到页面的数据 */
    doQiandaoList(params) {
        return this.callback('/api/customerQiandao/doQiandaoList.action', params);
    }
    /* 签到-签到 */
    doPublishQiandao(params) {
        return this.callback('/api/customerQiandao/doPublishQiandao.action', params);
    }

    tokenLogin(params) {
        return this.callback('/api/login/tokenLogin.action', params);
    }

    getUserInfo(params) {
        return this.callback('/api/login/getUserInfo.action', params);
    }

    bindMobile(params) {
        return this.callback('/api/login/bindMobile.action', params);
    }

    doHighItems(params) {
        return this.callback('/api/fanli/doHighItems.action', params);
    }

    doFanliItemList(params) {
        return this.callback('/api/page/doFanliItemList.action', params);
    }

    doCatalogue(params) {
        return this.callback('/api/fanli/doTopicList.action', params);
    }

    doTopicGoodsList(params) {
        return this.callback('/api/fanli/doTopicGoodsList.action', params);
    }

    doMiandanItemList(params) {
        return this.callback('/api/page/doMiandanItemList.action', params);
    }

    tljList(params) {
        return this.callback('/api/page/tljList.action', params);
    }

    freeProductList(params) {
        return this.callback('/api/page/freeProductList.action', params);
    }

    doDaeFanliList(params) {
        return this.callback('/api/page/doDaeFanliList.action', params);
    }
    /* 分享给好友-获得积分 */
    doPublishCreditLogSharePoster(params) {
        return this.callback('/api/customerCreditLog/doPublishCreditLogSharePoster.action', params);
    }

    doModifyMobile(params) {
        return this.callback('/api/login/modifyMobile.action', params);
    }

    doPublishFeedback(params) {
        return this.callback('/api/customerFeedback/doPublishFeedback.action', params);
    }

    queryDictionary(params) {
        return this.callback('/api/dictionary/queryDictionary.action', params);
    }

    doSiteList(params) {
        return this.callback('/api/site/doSiteList.action', params);
    }

    doOutModuleList(params) {
        return this.callback('/api/site/doOutModuleList.action', params);
    }

    doInsideModuleList(params) {
        return this.callback('/api/site/doInsideModuleList.action', params);
    }
    //查看图文资讯      
    doViewCeefax(params) {
        return this.callback('/api/customer/ceefax/doViewCeefax.action', params);
    }
    //请求新人欢迎消息      
    getWelcomeCeefax(params) {
        return this.callback('/api/customer/ceefax/getWelcomeCeefax.action', params);
    }

    doShareContent(params) {
        return this.callback('/api/customerRecommender/doShareContent.action', params);
    }

    getSharePoster(params) {
        return this.callback('/api/customerRecommender/getSharePoster.action', params);
    }

    doSettingInfo(params) {
        return this.callback('/api/setting/doSettingInfo.action', params);
    }

    doItemList(params) {
        return this.callback('/api/kuaizhan/doItemList.action', params);
    }

    doShareUrl(params) {
        return this.callback('/api/kuaizhan/doShareUrl.action', params);
    }

    gotoBuy(params) {
        return this.callback('/api/fanli/gotoBuy.action', params);
    }
    /* 记录viewLog */
    doPublishViewLog(params) {
        return this.callback('/api/customer/viewLog/doPublishViewLog.action', params);
    }
    /* 用户权益详情以及差距 */
    doCustomerLevel(params) {
        return this.callback('/api/customer/customerLevel/doCustomerLevel.action', params);
    }
    /* 用户申请升级 */
    doApplyLevel(params) {
        return this.callback('/api/customer/customerLevel/doApplyLevel.action', params);
    }

    doSiteDetailList(params) {
        return this.callback('/api/page/doSiteDetailList.action', params);
    }
    /**
     *分享相关 
     */
     doShareSignature(params) {
        return this.callback('/api/customer/order/doShareSignature.action', params);
    }

    doSharePosition(params) {
        this.doShareSignature({
            location: window.location.href.split('#')[0],
            clientType: params.clientType
        }).then((res) => {
            if (res.success) {
                params.link = 'https://mall.huitao588.top/index.html#/start?state=goodsShared&clientType=' + params.clientType + '&recommend=' + params.userId + '&id=' + params.id;
                this.doShareWeb(params, res);
            }
        }).catch((res) => {
            console.log(res);
        }).finally((res) => {
        });
    }
    doShareWap(paramsFromPage) {
        this.doShareSignature({
            location: window.location.href.split('#')[0],
            clientType: paramsFromPage.clientType
        }).then((res) => {
            if (res.success) {
                let params = {
                    title: '',
                    imgUrl: 'https://cdn.huizhuanvip.com/jianzhi/register/1581505629404118.png',
                };
                let url = this.doUrl();
                params.desc = "LEVEL实习暑期工，薪资高-有担保-结算快，戳一戳看看吧！";
                params.link = url + '/index.html#/start?state=sharedModuleJob&clientType=' + paramsFromPage.clientType + '&recommend=' + paramsFromPage.customerId;
                params.title = '月薪5000元的暑期兼职 钱多事少离家近 名额有限 速来报名吧~';
                params.imgUrl = 'https://cdn.huizhuanvip.com/jianzhi/app/images/icon_summer.png';
                this.doShareWeb(params, res);
            }
        }).catch((res) => {
            console.log(res);
        }).finally((res) => {
        });
    }
    doShareWeb(params, res) {
        wx.config({
            debug: false, // true:调试时候弹窗
            appId: res.module.appId, // 微信appid
            timestamp: res.module.timestamp, // 时间戳
            nonceStr: res.module.nonceStr, // 随机字符串
            signature: res.module.signature, // 签名
            jsApiList: [
                // 所有要调用的 API 都要加到这个列表中
                'updateAppMessageShareData',
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'scanQRCode',
            ]
        })
        wx.updateAppMessageShareData({
            title: params.title,
            desc: params.desc,
            link: params.link,
            imgUrl: params.imgUrl,
            success: function () {
            }
        });
        wx.onMenuShareAppMessage({
            title: params.title,
            desc: params.desc,
            link: params.link,
            imgUrl: params.imgUrl,
            success: function () {
                // 设置成功
            }
        });
        wx.onMenuShareQQ({
            title: params.title,
            desc: params.desc,
            link: params.link,
            imgUrl: params.imgUrl,
            success: function () {
                // 设置成功
            }
        });
        wx.onMenuShareTimeline({
            title: params.title,
            desc: params.desc,
            link: params.link,
            imgUrl: params.imgUrl,
            success: function () {
            }
        });
    }
    doScanQRCode(res) {
        wx.config({
            debug: false, // true:调试时候弹窗
            appId: res.module.appId, // 微信appid
            timestamp: res.module.timestamp, // 时间戳
            nonceStr: res.module.nonceStr, // 随机字符串
            signature: res.module.signature, // 签名
            jsApiList: [
                // 所有要调用的 API 都要加到这个列表中
                'scanQRCode',
            ]
        })
        wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode", "qrCode",], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
                var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                alert("SUCCESS========" + result)
                //  window.location.href = result;//因为我这边是扫描后有个链接，然后跳转到该页面
            }
        });
    }
    doUrl() {
        return requestUrl;
    }
    /**
     * 聊天使用的
     */
    // 调用服务器获得极光登录参数
    getJiGuangParams(params) {
        return this.callback('/api/userUnionLogin/getJiGuangParams.action', params);
    }
    // 调用服务器获得用户报名的职位信息
    getSignUpList(params) {
        return this.callback('/api/signUp/getSignUpList.action', params);
    }

    callback(url, data) {
        return new Promise((resolve, reject) => {
            if (url.indexOf("thirdparty") != -1) {
                url = "http://192.168.1.118:8082" + url;
                console.log(url);
            } else {
                if (url.substr(0, 4) !== 'http') {
                    url = requestUrl + url;
                }
            }
            data.clientType = 7;
            axios({
                url: url,
                data: data,
                method: 'POST',
                header: {
                    'Content-Type': 'application/json'
                }
            }).then((res) => {
                if (res.data) {
                    resolve(res.data);
                }
            }).catch((error) => {
                resolve({ success: false, error: "服务器连接失败" })
            })
        });
    }
}