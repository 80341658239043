import React, { Component } from 'react';
import { connect } from 'react-redux';
import { constants } from '../login/store';
import { Toast } from 'antd-mobile';
import Api from '../../utils/Api';
import { ParseParams } from '../../utils/params';
const getApi = Api.getInstance;

class Start extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    return (
      <div></div>
    )
  }
  async componentDidMount() {
    let params = ParseParams(window.location.search);
    this.props.setClientType({ clientType: params.clientType });
    try {
      await this.login(params);
    }
    finally {
      let { state = '', id = 0, recommend, topicId,image } = params;
      let array = state.split("_");
      if (array.length > 1) {
        state = array[0];
        id = array[1];
        console.log("state1===="+state+"===id==="+id)
      }
      if (array.length > 2) {
        recommend = array[2];
      }
      console.log("state2===="+state+"===id==="+id)
      if (state === 'goodsShared') {
        this.props.history.push('/' + state + '?id=' + id + '&recommend=' + recommend)
      }
      else if (state === 'topicList') {
        this.props.history.push('/topicList?topicId=' + topicId);
      }
      else if(state === 'login'){
        this.props.history.replace("/register?recommend=" + recommend)
      }
      else {
        this.props.history.replace("/wallet")
      }
    }
  }
  async login(params) {
    console.log("code1======"+params.code)
    if (params.code) {
      let res = await getApi().codeLogin({
        code: params.code,
        clientType: 7,
        recommend: params.recommend || null,
        uuid: "mall",
      });
      if (res.module) {
        console.log("code2======"+params.code)
        this.props.setUserInfo(res.module);
      }
      else {
        Toast.info(res.error, 1)
      }
    }
    else {
      // let userInfo = this.props.userInfo;
      // if (userInfo && userInfo.token) {
        let res = await getApi().tokenLogin({
          token: params.token,
          customerId: params.customerId,
        });
        if (res.module) {
          this.props.setUserInfo(res.module);
        }
        else {
          Toast.info(res.error, 1)
        }
      // }
    }
  }
}

const mapState = (state) => ({
  userInfo: state.login.userInfo,
  clientType: state.login.clientType
})

const mapDispatch = (dispatch) => ({
  setUserInfo(item) {
    dispatch({
      type: constants.USERINFO,
      payload: item
    })
  },
  setClientType(value) {
    dispatch({
      type: constants.CLIENT_TYPE,
      payload: value,
    })
  },
})

export default connect(mapState, mapDispatch)(Start);