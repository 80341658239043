export const USERINFO = "userInfo/userInfo";
export const DICTIONARYLIST = "dictionaryList/dictionaryList";
export const CLEAR = "clear/clear";
export const CLIENT_TYPE = "login/clientType";
export const SITELIST = 'siteList/siteList';
export const defaultState = {
    userInfo: {
        token: '8479d57cad0a449d9f9ce2b09bf1c79e',
        customerId:'9c767761cad1e4dfea1f86c466ebcbf01',
        clientType:4,
        customerType:1
    },
    dictionaryList: [],
    clientType: 4,
    type:{
        mall:0,
        express:1
    },
    siteList:[]
};
