import React, { Component, lazy, Suspense } from 'react';

import { Route, HashRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Start from './pages/start/index';
import Api from './utils/Api';
const browserHistory = createBrowserHistory();
// const Start = lazy(() => import('./pages/start/index'));
const Login = lazy(() => import('./pages/login/index'));
const Wallet = lazy(() => import('./pages/wallet/index'));
const Member = lazy(() => import('./pages/member/index'));
const TakeCashList = lazy(() => import('./pages/takeCashList/index'));
const OrderList = lazy(() => import('./pages/orderList/index'));
const Feedback = lazy(() => import('./pages/feedback/index'));
const Setting = lazy(() => import('./pages/setting/index'));
const ChangeMobile = lazy(() => import('./pages/setting/changeMobile/index'));
const AccountDetail = lazy(() => import('./pages/accountDetail/index'));
const CreditDetail = lazy(() => import('./pages/creditDetail/index'));
const CustomerService = lazy(() => import('./pages/customerService/index'));
const Invite = lazy(() => import('./pages/invite/index'));
const InvitePoster = lazy(() => import('./pages/invite/invitePoster/index'));
const InviteRecord = lazy(() => import('./pages/invite/inviteRecord/index'));
const InviteReceived = lazy(() => import('./pages/invite/inviteReceived/index'));
const PrivacyPolicy = lazy(() => import('./pages/privacyPolicy/index'));
const TakeCash = lazy(() => import('./pages/takeCash/index'));
const TakeCashSuccess = lazy(() => import('./pages/takeCash/takeCashSuccess/index'));
const SignIn = lazy(() => import('./pages/signIn/index'));
const Register = lazy(() => import('./pages/register/index'));


require('./assets/css/m_base.css');
const getApi = Api.getInstance;

class App extends Component {
    render() {
        return (
            <Suspense fallback={<div></div>}>
                <HashRouter history={browserHistory}>
                    <React.Fragment>
                        {/* <Router history={browserHistory}> */}
                        {/* <Route path='/' exact component={asyncComponent(() => load('./pages/start/index'))}></Route>*/}
                        <Route path='/' exact component={Start}></Route>
                        <Route path='/start' exact component={Start}></Route>
                        <Route path='/login' exact component={Login}></Route>
                        <Route path='/wallet' exact component={Wallet}></Route>
                        <Route path='/member' exact component={Member}></Route>
                        <Route path='/takeCashList' exact component={TakeCashList}></Route>
                        <Route path='/orderList' exact component={OrderList}></Route>
                        <Route path='/feedback' exact component={Feedback}></Route>
                        <Route path='/setting' exact component={Setting}></Route>
                        <Route path='/changeMobile' exact component={ChangeMobile}></Route>
                        <Route path='/accountDetail' exact component={AccountDetail}></Route>
                        <Route path='/creditDetail' exact component={CreditDetail}></Route>
                        <Route path='/customerService' exact component={CustomerService}></Route>
                        <Route path='/invite' exact component={Invite}></Route>
                        <Route path='/invitePoster' exact component={InvitePoster}></Route>
                        <Route path='/inviteRecord' exact component={InviteRecord}></Route>
                        <Route path='/inviteReceived' exact component={InviteReceived}></Route>
                        <Route path='/privacyPolicy' exact component={PrivacyPolicy}></Route>
                        <Route path='/takeCash' exact component={TakeCash}></Route>
                        <Route path='/takeCashSuccess' exact component={TakeCashSuccess}></Route>
                        <Route path='/signIn' exact component={SignIn}></Route>
                        <Route path='/register' exact component={Register}></Route>
                        {/* </Router> */}
                    </React.Fragment>
                </HashRouter>
            </Suspense>
        );
    }
}

export default App;
