import * as constants from './constants';

export default (state = constants.defaultState, { type, payload }) => {
	let newState = JSON.parse(JSON.stringify(state));
	newState.dictionaryList = state.dictionaryList;


	switch (type) {
		case constants.USERINFO: {
			newState.userInfo = payload;
			break;
		}
		case 'persist/REHYDRATE':{
			if(payload){
				var incoming = payload.login; // Carts is the name of the reducer
				if (incoming){
					newState= {...newState, ...incoming};
				}
			}
			break;
		}
		case constants.SITELIST: {
			newState.siteList = payload;
			break;
		}
		case constants.CLEAR:{
			newState =  {
				userInfo: {
					token: null,
					customerId: '3719c2d92b23b4b0680cd1e32fbe9a4e8',
					clientType:4
				},
				dictionaryList: [],
				goodInfo:{}
			};
			// alert("123")
			break;
		}
		case constants.CLIENT_TYPE: {
			newState.clientType = payload.clientType;
			break;
		}
		default:
			return newState;
	}
	// console.log('newState'+JSON.stringify(newState));
	return newState;
}